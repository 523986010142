import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';

import { login as loginApi } from '../api/api'; // Import the login function
import LanguageSwitcher from '../components/LanguageSwitcher';

import '../styles/Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth(); // Use the login function from AuthContext
  const { t } = useTranslation();

  // Check if the user is already logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/'); // Redirect to dashboard if already logged in
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const data = await loginApi(username, password); // Call the API
      const token = data.token;

      login(token); // Update authentication state
      navigate('/'); // Redirect to dashboard
    } catch (err) {
      // Display specific error messages based on the response
      if (err.response && err.response.status === 400) {
        setError('Invalid username or password. Please try again.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-background">
      <main className="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit}>
          <h1 className="h3 mb-3 fw-normal">{t('please_sign_in')}</h1>

          {/* Error message */}
          {error && (
            <div
              className="alert alert-danger"
              role="alert"
              aria-live="assertive"
            >
              {error}
            </div>
          )}

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingUsername"
              placeholder={t('username')}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <label htmlFor="floatingUsername">{t('username')}</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder={t('password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">{t('password')}</label>
          </div>

          <button
            className="btn btn-danger w-100 py-2"
            type="submit"
            disabled={loading}
          >
            {loading ? t('signing_in') : t('sign_in')}
          </button>

          <p className="mt-5 mb-3 text-body-secondary">&copy; 2024</p>
        </form>
        <div className="d-flex justify-content-center mt-3">
          <LanguageSwitcher />
        </div>
      </main>
    </div>
  );
}

export default Login;
