// src/components/Layout.js
import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';

import '../styles/Layout.css';


const Layout = ({ children }) => {
  return (
    <div className="d-flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-grow-1 layout-content">
        <Header />
        <div className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
