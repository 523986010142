import React from 'react';
import { useTranslation } from 'react-i18next';

const LayoutPageHeader = ({ title, description, buttonElement }) => {
  const { t } = useTranslation();

  return (
    <>
    <div className="d-flex justify-content-between align-items-center">
      
        <h1>{t(title)}</h1>
      
        {buttonElement && <div>{buttonElement}</div>}
      
    </div>
    {description && <p>{description}</p>}
    <hr className='mb-4' />
    </>
  );
};

export default LayoutPageHeader;
