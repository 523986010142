import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getQRCodeDetails, updateQRCode, createQRCode, getMenus } from '../api/api';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';

const LinkForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook

  const [menuId, setMenuId] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [menus, setMenus] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(isEdit);

  useEffect(() => {
    const fetchQRCodeDetails = async () => {
      try {
        const qrCode = await getQRCodeDetails(id);
        setMenuId(qrCode.menu.id);
        setSlug(qrCode.slug || '');
        setDescription(qrCode.description || '');
      } catch (err) {
        setError('Failed to load QR code details');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchMenus = async () => {
      try {
        const menusData = await getMenus();
        setMenus(menusData.results);
      } catch (err) {
        setError('Failed to load menus');
      }
    };

    if (isEdit) {
      fetchQRCodeDetails();
    }
    fetchMenus();
  }, [id, isEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!menuId || !menuId.toString().trim() || !slug.trim()) {
      setError(t('please_fill_in_all_required_fields')); // Use translation
      return;
    }

    const qrCodeData = {
      menu: menuId,
      slug,
      description,
    };

    try {
      if (isEdit) {
        await updateQRCode(id, qrCodeData);
        setSuccessMessage(t('link_updated_successfully')); // Use translation
      } else {
        await createQRCode(qrCodeData);
        setSuccessMessage(t('link_created_successfully')); // Use translation
      }
      setTimeout(() => navigate('/links'), 2000);
    } catch (err) {
      setError(err.message || t(`failed_to_${isEdit ? 'update' : 'create'}_link`)); // Use translation
    }
  };

  if (isLoading) {
    return <div className="container py-4">{t('loading')}</div>; // Use translation
  }

  return (
    <div className="container py-4">
      <LayoutPageHeader
        title={isEdit ? t('edit_link') : t('create_link')}
      />
      
      <div className="row">
        <div className="col-xl-6 col-12">
          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            {/* Menu */}
            <div className="mb-3">
              <label htmlFor="menu" className="form-label">
                {t('menu')} <span className="text-danger">*</span> {/* Use translation */}
              </label>
              <select
                id="menu"
                className="form-control form-control-sm"
                value={menuId}
                onChange={(e) => setMenuId(e.target.value)}
                required
              >
                <option value="">{t('select_menu')}</option> {/* Use translation */}
                {menus.map((menu) => (
                  <option key={menu.id} value={menu.id}>
                    {menu.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Slug */}
            <div className="mb-3">
              <label htmlFor="slug" className="form-label">
                {t('slug')} <span className="text-danger">*</span> {/* Use translation */}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control form-control-sm"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                required
              />
            </div>
            {/* Description */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t('description')} {/* Use translation */}
              </label>
              <textarea
                id="description"
                className="form-control form-control-sm"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/* Submit Button */}
            <button type="submit" className="btn btn-primary btn-sm">
              {isEdit ? t('update') : t('create')} {/* Use translation */}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LinkForm;