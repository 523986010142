import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProduct, updateProduct, getIngredients, createProduct, getCategories, getProductCategoriesByProductId, createProductCategory, deleteProductCategory } from '../api/api';
import ImageUploadWithPreview from '../components/ImageUploadWithPreview';
import LayoutPageHeader from '../components/common/LayoutPageHeader'; // Import LayoutPageHeader
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ProductForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(isEdit);
  const [photo, setPhoto] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const data = await getIngredients();
        setIngredients(data.results);
      } catch (err) {
        setError(t('failed_to_load_ingredients'));
      }
    };

    const fetchProductDetails = async () => {
      try {
        const product = await getProduct(id);
        setName(product.name);
        setDescription(product.description || '');
        setPrice(product.price.toString());
        setSelectedIngredients(product.ingredients.map((ingredient) => ingredient.id));
        if (product.photo) {
          setPhoto(product.photo); // Load photo if editing and one exists
        }
        const productCategories = await getProductCategoriesByProductId(1000, 1, 'sort_order', id);
        setSelectedCategories(productCategories.results.map((category) => category.category.id));
      } catch (err) {
        setError(t('failed_to_load_product_details'));
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(data.results);
      } catch (err) {
        setError(t('failed_to_load_categories'));
      }
    };

    fetchIngredients();
    fetchCategories();
    if (isEdit) {
      fetchProductDetails();
    }
  }, [id, isEdit, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!name.trim() || !price.trim() || !description.trim()) {
      setError(t('please_fill_in_all_required_fields'));
      return;
    }

    const productData = {
      name,
      description,
      price: parseFloat(price), // Convert price to a float
      ingredients: selectedIngredients, // Use the array of IDs directly
    };

    if (isEdit) {
      if (photo === null) {
        productData.photo = null; // If photo is null, send null
      } else if (typeof photo !== 'string') {
        productData.photo = photo; // If photo is not a string (newly uploaded), send the photo
      }
    } else {
      if (photo) {
        productData.photo = photo; // If creating and photo exists, send the photo
      }
    }

    try {
      let productId;
      if (isEdit) {
        await updateProduct(id, productData);
        productId = id;
        setSuccessMessage(t('product_updated_successfully'));
      } else {
        const createdProduct = await createProduct(productData);
        productId = createdProduct.id;
        setSuccessMessage(t('product_created_successfully'));
      }

      // Fetch existing product categories
      const existingProductCategories = await getProductCategoriesByProductId(1000, 1, 'sort_order', productId);
      const existingCategoryIds = existingProductCategories.results.map((category) => category.category.id);

      // Create new product categories
      await Promise.all(
        selectedCategories
          .filter((categoryId) => !existingCategoryIds.includes(categoryId))
          .map((categoryId) =>
            createProductCategory({ product: productId, category: categoryId })
          )
      );

      // Delete removed product categories
      await Promise.all(
        existingCategoryIds
          .filter((categoryId) => !selectedCategories.includes(categoryId))
          .map((categoryId) => {
            const productCategory = existingProductCategories.results.find((pc) => pc.category.id === categoryId);
            return deleteProductCategory(productCategory.id);
          })
      );

      setTimeout(() => navigate('/products'), 2000);
    } catch (err) {
      setError(err.message || t(`failed_to_${isEdit ? 'update' : 'create'}_product`));
    }
  };

  const handleIngredientChange = (id) => {
    if (selectedIngredients.includes(id)) {
      setSelectedIngredients((prev) => prev.filter((ingredientId) => ingredientId !== id));
    } else {
      setSelectedIngredients((prev) => [...prev, id]);
    }
  };

  const handleCategoryChange = (id) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories((prev) => prev.filter((categoryId) => categoryId !== id));
    } else {
      setSelectedCategories((prev) => [...prev, id]);
    }
  };

  if (isLoading) {
    return <div className="container py-4">{t('loading')}</div>;
  }

  return (
    <div className="container py-4">

      <LayoutPageHeader
        title={isEdit ? t('edit_product') : t('create_product')}
      />
      
      <div className="row">
        <div className="col-xl-6 col-12">
          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                {t('name')} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                className="form-control form-control-sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            {/* Description */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t('description')} <span className="text-danger">*</span>
              </label>
              <textarea
                id="description"
                className="form-control form-control-sm"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            {/* Price */}
            <div className="mb-3">
              <label htmlFor="price" className="form-label">
                {t('price')} <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="price"
                className="form-control form-control-sm"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>
            {/* Photo Upload with Preview */}
            <div className="mb-3">
              <label className="form-label">{t('photo')}</label>
              <ImageUploadWithPreview
                onImageUpload={setPhoto} // Pass the photo state setter
                initialImage={photo} // Pass the initial photo
              />
            </div>
            {/* Ingredients */}
            <div className="mb-3">
              <label className="form-label">{t('ingredients')}</label>
              <div className="d-flex flex-wrap">
                {ingredients.map((ingredient) => (
                  <div key={ingredient.id} className="form-check me-3">
                    <input
                      type="checkbox"
                      id={`ingredient-${ingredient.id}`}
                      className="form-check-input"
                      value={ingredient.id}
                      checked={selectedIngredients.includes(ingredient.id)}
                      onChange={() => handleIngredientChange(ingredient.id)}
                    />
                    <label htmlFor={`ingredient-${ingredient.id}`} className="form-check-label">
                      {ingredient.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* Submit Button */}
            <button type="submit" className="btn btn-primary btn-sm">
              {isEdit ? t('update') : t('create')}
            </button>
          </form>
        </div>
        <div className="col-xl-6 col-12">
          <h5>{t('product_categories')}</h5>
          <p>{t('choose_one_or_more_categories')}</p>
          <div className="d-flex flex-column">
            {categories.map((category) => (
              <div key={category.id} className="form-check mb-2">
                <input
                  type="checkbox"
                  id={`category-${category.id}`}
                  className="form-check-input"
                  value={category.id}
                  checked={selectedCategories.includes(category.id)}
                  onChange={() => handleCategoryChange(category.id)}
                />
                <label htmlFor={`category-${category.id}`} className="form-check-label">
                  {category.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
