import React, { useState } from 'react';
import { changePassword } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LayoutPageHeader from '../components/common/LayoutPageHeader';

const ChangePassword = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError(t('new_passwords_do_not_match'));
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);
      setSuccess(t('password_changed_successfully'));
      setError(null);
      setTimeout(() => {
        navigate('/');
      }, 2000); // Redirect to dashboard after 2 seconds
    } catch (err) {
      setError(t('failed_to_change_password'));
      setSuccess(null);
    }
  };

  return (
    <div className="container py-4">
      
      <LayoutPageHeader title={t('change_password')} />

      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="oldPassword" className="form-label">{t('old_password')}</label>
          <input
            type="password"
            className="form-control"
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">{t('new_password')}</label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">{t('confirm_new_password')}</label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">{t('change_password')}</button>
      </form>
    </div>
  );
};

export default ChangePassword;