import React, { useEffect, useState } from 'react';
import { getProducts, getMenus, getQRCodes, getUserDetails } from '../api/api'; // Import the getProducts, getMenus, getQRCodes, and getUserDetails functions
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { formatDate } from '../utils/utils';
import { Toast } from 'react-bootstrap'; // Import Toast
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';

import '../styles/Dashboard.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap icons

const Dashboard = () => {
  const [latestProducts, setLatestProducts] = useState([]);
  const [menus, setMenus] = useState([]); // Add state for menus
  const [links, setLinks] = useState([]); // Add state for links
  const [showToast, setShowToast] = useState(false); // Add state for toast
  const [currency, setCurrency] = useState({ symbol: '', minor_unit: 2 }); // Add state for currency
  const navigate = useNavigate(); // Initialize navigate
  const { t } = useTranslation(); // Add translation hook

  useEffect(() => {
    const fetchLatestProducts = async () => {
      try {
        const data = await getProducts(5, 1, '-created_at'); // Fetch the latest 5 products
        setLatestProducts(data.results);
      } catch (err) {
        console.error('Failed to load latest products', err);
      }
    };

    const fetchMenus = async () => {
      try {
        const data = await getMenus(5, 1, '-updated_at'); // Fetch the latest 5 menus
        setMenus(data.results);
      } catch (err) {
        console.error('Failed to load menus', err);
      }
    };

    const fetchLinks = async () => {
      try {
        const data = await getQRCodes(5, 1, '-updated_at'); // Fetch the latest 5 links
        setLinks(data.results);
      } catch (err) {
        console.error('Failed to load links', err);
      }
    };

    const fetchUserDetails = async () => {
      try {
        const userDetails = await getUserDetails();
        setCurrency(userDetails.company.currency); // Set currency from user details
      } catch (err) {
        console.error('Failed to load user details', err);
      }
    };

    fetchLatestProducts();
    fetchMenus();
    fetchLinks();
    fetchUserDetails(); // Fetch user details
  }, []);

  const handleViewAll = () => {
    navigate('/products'); // Navigate to the products page
  };

  const handleMenuClick = (id) => {
    navigate(`/menus/${id}/edit`);
  };

  const handleViewAllMenus = () => {
    navigate('/menus'); // Navigate to the menus page
  };

  const handleViewAllLinks = () => {
    navigate('/links'); // Navigate to the links page
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      setShowToast(true);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="container-fluid">

      <LayoutPageHeader 
        title={t('dashboard')} 
        description={t('welcome_message')}
      />

      <div className="row">
        {/* Page Heading */}

        {/* Recent Activities Section
        <div className="col-12 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Recent Activities</h5>
              <p className="card-text">Latest actions and updates in the system.</p>
              
            </div>
          </div>
        </div>
        */}

        {/* System Health Section
        <div className="col-12 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">System Health</h5>
              <p className="card-text">Current status and performance metrics.</p>
            </div>
          </div>
        </div> 
        */}

        {/* Links Section */}
        <div className="col-12 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">{t('links')}</h5>
              <p className="card-text">{t('explore_links')}</p>
              <div className="list-group">
                {links.map((link) => {
                  const url = `https://menu.justqrmenu.com/${link.slug}`;
                  return (
                    <div
                      className="list-group-item menu-item"
                      key={link.id}
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          <h5 className="mb-1">{link.menu.name}</h5>
                          <p className="mb-1">
                            URL: <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                            <i
                              className="bi bi-clipboard-check ms-2"
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCopyLink(url);
                              }}
                              onMouseEnter={(e) => e.target.style.color = 'green'}
                              onMouseLeave={(e) => e.target.style.color = ''}
                            ></i>
                          </p>
                          <p className="mb-1 text-muted" style={{ fontSize: '0.875rem' }}>
                            <i className="bi bi-clock" style={{ marginRight: '5px' }}></i>
                            {t('last_updated')}: {formatDate(link.updated_at)}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <button className="btn btn-primary mt-3" onClick={handleViewAllLinks}>
                {t('view_all')}
              </button>
            </div>
          </div>
        </div>

        {/* Menus Section */}
        <div className="col-12 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">{t('menus')}</h5>
              <p className="card-text">{t('explore_menus')}</p>
              <div className="list-group">
                {menus.map((menu) => (
                  <div
                    className="list-group-item menu-item"
                    key={menu.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleMenuClick(menu.id)}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <h5 className="mb-1">{menu.name}</h5>
                        <div className="mb-1">
                          {menu.categories.map((category) => (
                            <span key={category.id} className="badge bg-secondary me-1">
                              {category.name}
                            </span>
                          ))}
                        </div>
                        <p className="mb-1 text-muted" style={{ fontSize: '0.875rem' }}>
                          <i className="bi bi-clock" style={{ marginRight: '5px' }}></i>
                          {t('last_updated')}: {formatDate(menu.updated_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className="btn btn-primary mt-3" onClick={handleViewAllMenus}>
                {t('view_all')}
              </button>
            </div>
          </div>
        </div>

        {/* Latest Products Section */}
        <div className="col-12 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">{t('latest_products')}</h5>
              <p className="card-text">{t('check_latest_products')}</p>
              <div className="list-group">
                {latestProducts.map((product) => (
                  <div className="list-group-item" key={product.id}>
                    <div className="d-flex align-items-center">
                      <div className="product-img-wrapper me-3">
                        {product.photo ? (
                          <img src={product.photo} className="product-img" alt={product.name} />
                        ) : (
                          <div className="no-image">
                            <i className="bi bi-image" style={{ fontSize: '24px' }}></i>
                          </div>
                        )}
                      </div>
                      <div>
                        <h5 className="mb-1">{product.name}</h5>
                        <p className="mb-1">
                          {currency.symbol}
                          {(
                            Math.round(product.price * Math.pow(10, currency.minor_unit)) /
                            Math.pow(10, currency.minor_unit)
                          ).toFixed(currency.minor_unit)}
                        </p>
                        <div className="ingredients">
                          {product.ingredients.map((ingredient) => (
                            <span key={ingredient.id} className="ingredient-tag">
                              {ingredient.name}
                            </span>
                          ))}
                        </div>
                        <p className="mb-1 text-muted" style={{ fontSize: '0.875rem' }}>
                          <i className="bi bi-clock" style={{ marginRight: '5px' }}></i>
                          {t('last_updated')}: {formatDate(product.updated_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className="btn btn-primary mt-3" onClick={handleViewAll}>
                {t('view_all')}
              </button>
            </div>
          </div>
        </div>

        

        

      </div>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
      >
        <Toast.Body>{t('link_copied')}</Toast.Body>
      </Toast>
    </div>
  );
};

export default Dashboard;
