import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMenus, deleteMenu } from '../api/api';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { formatDate } from '../utils/utils'; // Import the formatDate function
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';
import ButtonCreate from '../components/common/ButtonCreate'; // Import ButtonCreate component

const Menus = () => {
  const [menus, setMenus] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const data = await getMenus();
        setMenus(data.results);
      } catch (err) {
        setError('Failed to load menus');
        console.error(err);
      }
    };

    fetchMenus();
  }, []);

  const handleCreateMenu = () => {
    navigate('/menus/create');
  };

  const handleEditMenu = (id) => {
    navigate(`/menus/${id}/edit`);
  };

  const handleDeleteMenu = async () => {
    try {
      await deleteMenu(selectedMenu.id);
      setMenus(menus.filter(menu => menu.id !== selectedMenu.id));
      setShowModal(false);
    } catch (err) {
      setError('Failed to delete menu');
      console.error(err);
    }
  };

  const openModal = (menu) => {
    setSelectedMenu(menu);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedMenu(null);
  };

  return (
    <div className="container py-4">

      <LayoutPageHeader
        title={t('menus')}
        buttonElement={
          <ButtonCreate onClick={handleCreateMenu} text={t('create_menu')} />
        } 
      />

      {error && <div className="alert alert-danger">{error}</div>}

      <div className="table-responsive">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('categories')}</th>
              <th>{t('updated_at')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {menus.map((menu) => (
              <tr key={menu.id} style={{ cursor: 'pointer' }} onClick={() => handleEditMenu(menu.id)}>
                <td>{menu.name}</td>
                <td>
                  {menu.categories.map((category) => (
                    <span key={category.id} className="badge bg-secondary me-1">
                      {category.name}
                    </span>
                  ))}
                </td>
                <td>{formatDate(menu.updated_at)}</td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-primary me-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditMenu(menu.id);
                    }}
                  >
                    {t('edit')}
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal(menu);
                    }}
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('delete_confirmation_menu', { menuName: selectedMenu?.name })}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={handleDeleteMenu}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Menus;