import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getIngredients, createIngredient, updateIngredient, deleteIngredient } from '../api/api';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';

const Ingredients = () => {
  const [ingredients, setIngredients] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [newIngredientName, setNewIngredientName] = useState('');
  const [editingIngredient, setEditingIngredient] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page')) || 1;
  const limit = parseInt(searchParams.get('limit')) || 20;
  const [totalCount, setTotalCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [ingredientToDelete, setIngredientToDelete] = useState(null);

  const { t } = useTranslation(); // Add translation hook

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const data = await getIngredients(limit, page);
        setIngredients(data.results);
        setTotalCount(data.count);
      } catch (err) {
        setError('Failed to load ingredients');
        console.error(err);
      }
    };

    fetchIngredients();
  }, [page, limit]);

  useEffect(() => {
    if (successMessage || error) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
        setError(null);
      }, 3000); // Clear alert after 3 seconds
      return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [successMessage, error]);

  const totalPages = Math.ceil(totalCount / limit);

  const updateQueryParams = (newPage, newLimit) => {
    setSearchParams({ page: newPage, limit: newLimit });
  };

  const handleCreateIngredient = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!newIngredientName.trim()) {
      setError('Ingredient name cannot be empty.');
      return;
    }

    try {
      const newIngredient = await createIngredient({ name: newIngredientName });
      setIngredients((prev) => [newIngredient, ...prev]);
      setSuccessMessage(`Ingredient "${newIngredient.name}" created successfully!`);
      setNewIngredientName('');
    } catch (err) {
      setError(err.message || 'Failed to create ingredient');
    }
  };

  const handleEditIngredient = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!editedName.trim()) {
      setError('Ingredient name cannot be empty.');
      return;
    }

    try {
      const updatedIngredient = await updateIngredient(editingIngredient.id, { name: editedName });
      setIngredients((prev) =>
        prev.map((ingredient) =>
          ingredient.id === updatedIngredient.id ? updatedIngredient : ingredient
        )
      );
      setSuccessMessage(`Ingredient updated to "${updatedIngredient.name}" successfully!`);
      setEditingIngredient(null);
      setEditedName('');
    } catch (err) {
      setError('Failed to update ingredient');
    }
  };

  const handleDeleteIngredient = async (id) => {
    try {
      await deleteIngredient(id);
      setIngredients((prev) => prev.filter((ingredient) => ingredient.id !== id));
      setSuccessMessage('Ingredient deleted successfully!');
    } catch (err) {
      setError('Failed to delete ingredient');
    }
  };

  const handleDeleteClick = (id) => {
    setIngredientToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (ingredientToDelete) {
      await handleDeleteIngredient(ingredientToDelete);
      setShowModal(false);
      setIngredientToDelete(null);
    }
  };

  const handleEditClick = (ingredient) => {
    setEditingIngredient(ingredient);
    setEditedName(ingredient.name);
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    await handleEditIngredient(e);
  };

  return (
    <div className="container py-4">
      <LayoutPageHeader
        title={t('ingredients')} // Use translation
      />

      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}

      <form onSubmit={handleCreateIngredient} className="d-flex mb-4">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder={t('enter_new_ingredient_name')}
            value={newIngredientName}
            onChange={(e) => setNewIngredientName(e.target.value)}
          />
          <button type="submit" className="btn btn-primary">
            {t('add_ingredient')}
          </button>
        </div>
      </form>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <label htmlFor="limit" className="form-label me-2 mb-0">
            {t('items_per_page')}:
          </label>
          <select
            id="limit"
            className="form-select d-inline-block w-auto"
            value={limit}
            onChange={(e) => {
              const newLimit = parseInt(e.target.value, 10);
              updateQueryParams(1, newLimit);
            }}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <span>{t('total_items')}: {totalCount}</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>{t('name')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {ingredients.map((ingredient) => (
              <tr key={ingredient.id}>
                {/* <td>{ingredient.id}</td> */}
                <td>
                  {editingIngredient?.id === ingredient.id ? (
                    <input
                      type="text"
                      className="form-control"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                    />
                  ) : (
                    ingredient.name
                  )}
                </td>
                <td>
                  {editingIngredient?.id === ingredient.id ? (
                    <>
                      <button className="btn btn-sm btn-outline-success me-2" onClick={handleSaveEdit}>
                        {t('save')}
                      </button>
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => setEditingIngredient(null)}
                      >
                        {t('cancel')}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-sm btn-outline-primary me-2"
                        onClick={() => handleEditClick(ingredient)}
                      >
                        {t('edit')}
                      </button>
                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleDeleteClick(ingredient.id);
                        }}
                      >
                        {t('delete')}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          disabled={page === 1}
          onClick={() => updateQueryParams(page - 1, limit)}
        >
          {t('previous')}
        </button>
        <span>
          {t('page')} {page} {t('of')} {totalPages}
        </span>
        <button
          className="btn btn-secondary"
          disabled={page === totalPages}
          onClick={() => updateQueryParams(page + 1, limit)}
        >
          {t('next')}
        </button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('delete_confirmation_ingredient', { ingredientName: ingredients.find(ing => ing.id === ingredientToDelete)?.name })}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Ingredients;
