import React, { useEffect, useState, useRef } from 'react';
import { getUserDetails } from '../api/api';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();

  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState(null);
  const fetchCalled = useRef(false); // Prevent duplicate fetch calls

  useEffect(() => {
    if (fetchCalled.current) return; // If already called, skip
    fetchCalled.current = true; // Mark as called

    const fetchUserName = async () => {
      try {
        const userDetails = await getUserDetails();
        setUserName(`${userDetails.first_name} ${userDetails.last_name}`);
        setCompanyName(`${userDetails.company.name}`);
      } catch (err) {
        setError('Failed to load user details');
        console.error(err);
      }
    };

    fetchUserName();
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light border-bottom"
      style={{ position: 'sticky', top: 0, zIndex: 1020 }}
    >
      <div className="container-fluid">
        {/* Sidebar Toggle Button for Mobile */}
        <button
          className="btn btn-dark d-md-none me-3"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
        >
          ☰
        </button>

        <h5 className="mb-0">{companyName}</h5>
        <div className="d-flex align-items-center">
          {error ? (
            <span className="text-danger me-3">{error}</span>
          ) : (
            <div className="dropdown">
              <span
                className="me-3 dropdown-toggle"
                id="userDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {userName}
              </span>
              <ul className="dropdown-menu" aria-labelledby="userDropdown">
                <li>
                  <Link className="dropdown-item" to="/change-password">
                    {t('change_password')}
                  </Link>
                  <Link className="dropdown-item" to="/logout">
                    {t('logout')}
                  </Link>
                </li>
              </ul>
            </div>
          )}
          
        </div>
      </div>
    </nav>
  );
};

export default Header;
