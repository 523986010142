import React from 'react';
import { FaPlus } from 'react-icons/fa';
import PropTypes from 'prop-types';

import '../../styles/ButtonCreate.css';

const ButtonCreate = ({ onClick, text }) => {
  return (
    <button className="btn btn-primary app--button_cretae" onClick={onClick}>
      <FaPlus className="me-2" />
      <span>{text}</span>
    </button>
  );
};

ButtonCreate.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

ButtonCreate.defaultProps = {
  onClick: () => {},
};

export default ButtonCreate;
