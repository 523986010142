// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Layout from './components/Layout';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import Ingredients from './pages/Ingredients';
import Products from './pages/Products';
import Categories from './pages/Categories';
import ProductForm from './pages/ProductForm'; // Import ProductForm component
import CategoryForm from './pages/CategoryForm';
import CategoryProducts from './pages/CategoryProducts'; // Import CategoryProducts component
import Menus from './pages/Menus'; // Import Menus component
import MenuForm from './pages/MenuForm'; // Import MenuForm component
import Links from './pages/Links'; // Import Links component
import LinkForm from './pages/LinkForm'; // Import LinkForm component
import Company from './pages/Company'; // Import Company component
import ChangePassword from './pages/ChangePassword'; // Import ChangePassword component

const routesWithLayout = [
  { path: '/', element: <Dashboard /> },
  { path: '/products', element: <Products /> },
  { path: '/products/create', element: <ProductForm isEdit={false} /> },
  { path: '/products/:id/edit', element: <ProductForm isEdit={true} /> },
  { path: '/categories', element: <Categories /> },
  { path: '/categories/create', element: <CategoryForm isEdit={false} /> },
  { path: '/categories/:id/edit', element: <CategoryForm isEdit={true} /> },
  { path: '/categories/:id/products', element: <CategoryProducts /> },
  { path: '/ingredients', element: <Ingredients /> },
  { path: '/menus', element: <Menus /> },
  { path: '/menus/create', element: <MenuForm isEdit={false} /> },
  { path: '/menus/:id/edit', element: <MenuForm isEdit={true} /> },
  { path: '/links', element: <Links /> },
  { path: '/links/create', element: <LinkForm isEdit={false} /> },
  { path: '/links/:id/edit', element: <LinkForm isEdit={true} /> },
  { path: '/company', element: <Company /> }, // New route
  { path: '/change-password', element: <ChangePassword /> }, // New route
  // Add more pages here
];

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Routes without layout */}
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          {/* Routes with layout */}
          {routesWithLayout.map(({ path, element }, index) => (
            <Route
              key={index}
              path={path}
              element={<PrivateRoute><Layout>{element}</Layout></PrivateRoute>}
            />
          ))}

          {/* 404 Route */}
          <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />

        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
