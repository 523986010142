import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'; // React Router for query parameters
import { getCategories, deleteCategory } from '../api/api'; // API function for categories
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import { useTranslation } from 'react-i18next';
import LayoutPageHeader from '../components/common/LayoutPageHeader';
import ButtonCreate from '../components/common/ButtonCreate'; // Import ButtonCreate component

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0); // Total items count
  const [searchParams, setSearchParams] = useSearchParams(); // Query parameters
  const [showModal, setShowModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [sortOrder] = useState('asc');

  // Get initial values from URL or set defaults
  const page = parseInt(searchParams.get('page')) || 1; // Default page = 1
  const limit = parseInt(searchParams.get('limit')) || 20; // Default limit = 20

  const navigate = useNavigate(); // Navigation hook
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories(limit, page, 'sort_order', sortOrder); // Always sort by sort_order
        setCategories(data.results);
        setTotalCount(data.count);
      } catch (err) {
        setError(t('failed_to_load_categories'));
        console.error(err);
      }
    };

    fetchCategories();
  }, [page, limit, sortOrder, t]); // Re-fetch when sortOrder changes

  const totalPages = Math.ceil(totalCount / limit);

  // Update query parameters
  const updateQueryParams = (newPage, newLimit) => {
    setSearchParams({ page: newPage, limit: newLimit });
  };

  const handleCreateCategory = () => {
    navigate('/categories/create'); // Redirect to create category page
  };

  const handleRowClick = (id) => {
    navigate(`/categories/${id}/edit`); // Redirect to edit page for the clicked category
  };

  const handleProductsClick = (id) => {
    navigate(`/categories/${id}/products`); // Redirect to products page for the clicked category
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteCategory(id);
      setCategories((prev) => prev.filter((category) => category.id !== id));
    } catch (err) {
      setError('Failed to delete category');
    }
  };

  const handleDeleteClick = (id) => {
    setCategoryToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (categoryToDelete) {
      await handleDeleteCategory(categoryToDelete);
      setShowModal(false);
      setCategoryToDelete(null);
    }
  };

  const handleSortOrderChange = (id, newSortOrder) => {
    setCategories((prev) => {
      const updatedCategories = prev.map((category) =>
        category.id === id ? { ...category, sort_order: newSortOrder } : category
      );
      return updatedCategories.sort((a, b) => a.sort_order - b.sort_order);
    });
  };

  return (
    <div className="container py-4">

        <LayoutPageHeader 
          title={t('categories')} 
          buttonElement={
            <ButtonCreate onClick={handleCreateCategory} text={t('create_category')} />
          }
        />

        {error && <div className="alert alert-danger">{error}</div>}

        {/* Limit Dropdown */}
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
            <label htmlFor="limit" className="form-label me-2">
                {t('items_per_page')}:
            </label>
            <select
                id="limit"
                className="form-select d-inline-block w-auto"
                value={limit}
                onChange={(e) => {
                const newLimit = parseInt(e.target.value, 10);
                updateQueryParams(1, newLimit); // Reset to page 1 when limit changes
                }}
            >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </select>
            </div>
            <div>
            <span>{t('total_items')}: {totalCount}</span>
            </div>
        </div>

        <div className="table-responsive">
            <table className="table table-hover table-sm">
            <thead>
                <tr>
                  <th>{t('name')}</th>
                  <th>{t('description')}</th>
                  <th>{t('sort_order')}</th>
                  <th>{t('updated_at')}</th>
                  <th>{t('actions')}</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                {categories.map((category, index) => (
                    <tr
                        key={category.id}
                        onClick={() => handleRowClick(category.id)}
                        style={{ cursor: 'pointer' }} // Add pointer cursor for clarity
                    >
                    <td>{category.name}</td>
                    <td>{category.description}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-sm btn-outline-secondary me-1"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering row click
                            handleSortOrderChange(category.id, category.sort_order - 1);
                          }}
                          disabled={index === 0}
                        >
                          ↑
                        </button>
                        {category.sort_order}
                        <button
                          className="btn btn-sm btn-outline-secondary ms-1"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering row click
                            handleSortOrderChange(category.id, category.sort_order + 1);
                          }}
                          disabled={index === categories.length - 1}
                        >
                          ↓
                        </button>
                      </div>
                    </td>
                    <td>{new Date(category.updated_at).toLocaleString()}</td>
                    <td>
                      <button
                        className="btn btn-secondary btn-sm me-2"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering row click
                          handleProductsClick(category.id);
                        }}
                      >
                        {t('products')}
                      </button>
                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleDeleteClick(category.id);
                        }}
                      >
                        {t('delete')}
                      </button>
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between align-items-center mt-3">
            <button
            className="btn btn-secondary btn-sm"
            disabled={page === 1}
            onClick={() => updateQueryParams(page - 1, limit)}
            >
            {t('previous')}
            </button>
            <span>
            {t('page')} {page} {t('of')} {totalPages}
            </span>
            <button
            className="btn btn-secondary btn-sm"
            disabled={page === totalPages}
            onClick={() => updateQueryParams(page + 1, limit)}
            >
            {t('next')}
            </button>
        </div>
      
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('confirm_deletion')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('delete_confirmation_category', { categoryName: categories.find(cat => cat.id === categoryToDelete)?.name })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {t('cancel')}
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
              {t('delete')}
            </Button>
          </Modal.Footer>
        </Modal>
      
    </div>
  );
};

export default Categories;
