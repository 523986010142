import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCategoryDetails, updateCategory, createCategory } from '../api/api';
import { useTranslation } from 'react-i18next';
import LayoutPageHeader from '../components/common/LayoutPageHeader';


const CategoryForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(isEdit);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const category = await getCategoryDetails(id);
        setName(category.name);
        setDescription(category.description || '');
        setSortOrder(category.sort_order.toString());
      } catch (err) {
        setError('Failed to load category details');
      } finally {
        setIsLoading(false);
      }
    };

    if (isEdit) {
      fetchCategoryDetails();
    }
  }, [id, isEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!name.trim() || !sortOrder.trim()) {
      setError('Please fill in all required fields');
      return;
    }

    const categoryData = {
      name,
      description,
      sort_order: parseInt(sortOrder, 10),
    };

    try {
      if (isEdit) {
        await updateCategory(id, categoryData);
        setSuccessMessage('Category updated successfully!');
      } else {
        await createCategory(categoryData);
        setSuccessMessage('Category created successfully!');
      }
      setTimeout(() => navigate('/categories'), 2000);
    } catch (err) {
      setError(err.message || `Failed to ${isEdit ? 'update' : 'create'} category`);
    }
  };

  if (isLoading) {
    return <div className="container py-4">Loading...</div>;
  }

  return (
    <div className="container py-4">

      <LayoutPageHeader title={isEdit ? t('edit_category') : t('create_category')} />

      <div className="row">
        <div className="col-xl-6 col-12">
          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                {t('name')} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                className="form-control form-control-sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            {/* Description */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t('description')}
              </label>
              <textarea
                id="description"
                className="form-control form-control-sm"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/* Sort Order */}
            <div className="mb-3">
              <label htmlFor="sortOrder" className="form-label">
                {t('sort_order')} <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="sortOrder"
                className="form-control form-control-sm"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                required
              />
            </div>
            {/* Submit Button */}
            <button type="submit" className="btn btn-primary btn-sm">
              {isEdit ? t('update') : t('create')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;