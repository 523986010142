import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // React Router for query parameters
import { getProducts, deleteProduct, getUserDetails } from '../api/api'; // API function for products
import { useNavigate } from 'react-router-dom'; // Add useNavigate import
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';
import ButtonCreate from '../components/common/ButtonCreate'; // Import ButtonCreate component

const Products = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0); // Total items count
  const [searchParams, setSearchParams] = useSearchParams(); // Query parameters
  const [showModal, setShowModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [currency, setCurrency] = useState({ symbol: '', minor_unit: 2 }); // Add state for currency

  // Get initial values from URL or set defaults
  const page = parseInt(searchParams.get('page')) || 1; // Default page = 1
  const limit = parseInt(searchParams.get('limit')) || 20; // Default limit = 20

  const navigate = useNavigate(); // Add navigate hook
  const { t } = useTranslation(); // Add translation hook

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts(limit, page);
        setProducts(data.results);
        setTotalCount(data.count);
      } catch (err) {
        setError('Failed to load products');
        console.error(err);
      }
    };

    const fetchUserDetails = async () => {
      try {
        const userDetails = await getUserDetails();
        setCurrency(userDetails.company.currency); // Set currency from user details
      } catch (err) {
        setError('Failed to load user details');
        console.error(err);
      }
    };

    fetchProducts();
    fetchUserDetails(); // Fetch user details
  }, [page, limit]); // Re-fetch when page or limit changes

  const totalPages = Math.ceil(totalCount / limit);

  // Update query parameters
  const updateQueryParams = (newPage, newLimit) => {
    setSearchParams({ page: newPage, limit: newLimit });
  };

  const handleCreateProduct = () => {
    window.location.href = '/products/create';
  };

  const handleRowClick = (id) => {
    navigate(`/products/${id}/edit`); // Redirect to edit page for the clicked product
  };

  const handleDeleteProduct = async (id) => {
    try {
      await deleteProduct(id);
      setProducts((prev) => prev.filter((product) => product.id !== id));
    } catch (err) {
      setError('Failed to delete product');
    }
  };

  const handleDeleteClick = (id) => {
    setProductToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (productToDelete) {
      await handleDeleteProduct(productToDelete);
      setShowModal(false);
      setProductToDelete(null);
    }
  };

  return (
    <div className="container py-4">

      <LayoutPageHeader
        title={t('products')}
        buttonElement={
          <ButtonCreate onClick={handleCreateProduct} text={t('add_product')} />
        } 
      />

      {error && <div className="alert alert-danger">{error}</div>}

      {/* Limit Dropdown */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <label htmlFor="limit" className="form-label me-2">
            {t('items_per_page')}:
          </label>
          <select
            id="limit"
            className="form-select d-inline-block w-auto "
            value={limit}
            onChange={(e) => {
              const newLimit = parseInt(e.target.value, 10);
              updateQueryParams(1, newLimit); // Reset to page 1 when limit changes
            }}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <span>{t('total_items')}: {totalCount}</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>{t('image')}</th> {/* Add Image column */}
              <th>{t('name')}</th>
              {/* <th>Description</th> */}
              <th>{t('price')}</th>
              {/* <th>Created At</th> */}
              <th>{t('updated_at')}</th>
              <th>{t('actions')}</th> {/* Add Actions column */}
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {products.map((product) => (
              <tr
                key={product.id}
                onClick={() => handleRowClick(product.id)}
                style={{ cursor: 'pointer', verticalAlign: 'middle' }} // Add pointer cursor for clarity and verticalAlign: 'middle' for vertical centering
              >
                {/* <td>{product.id}</td> */}
                <td>
                  {product.photo ? (
                    <img loading="lazy" src={product.photo} alt={product.name} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                  ) : (
                    <div className="no-image" style={{ width: '50px', height: '50px', backgroundColor: '#e0e0e0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <i className="bi bi-image" style={{ fontSize: '24px' }}></i>
                    </div>
                  )}
                </td>
                <td>{product.name}</td>
                <td>
                  {currency.symbol} {product.price}
                  {/* {(
                    Math.round(product.price * Math.pow(10, currency.minor_unit)) /
                    Math.pow(10, currency.minor_unit)
                  ).toFixed(currency.minor_unit)} */}
                </td>
                {/* <td>{product.description}</td> */}
                {/* <td>{new Date(product.created_at).toLocaleString()}</td> */}
                <td>{new Date(product.updated_at).toLocaleString()}</td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handleDeleteClick(product.id);
                    }}
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary btn-sm"
          disabled={page === 1}
          onClick={() => updateQueryParams(page - 1, limit)}
        >
          {t('previous')}
        </button>
        <span>
          {t('page')} {page} {t('of')} {totalPages}
        </span>
        <button
          className="btn btn-secondary btn-sm"
          disabled={page === totalPages}
          onClick={() => updateQueryParams(page + 1, limit)}
        >
          {t('next')}
        </button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('delete_confirmation', { productName: products.find(prod => prod.id === productToDelete)?.name })}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Products;
