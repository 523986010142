// src/pages/NotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
      <h1 className="display-1 text-danger">404</h1>
      <p className="lead">{t('sorry_page_not_found')}</p>
      <button className="btn btn-primary mt-3" onClick={() => navigate('/')}>
        {t('go_back_to_dashboard')}
      </button>
    </div>
  );
};

export default NotFound;
