import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Dil dosyalarını içe aktarın
import enTranslation from './locales/en/translation.json';
import trTranslation from './locales/tr/translation.json';

i18n
  .use(LanguageDetector) // Tarayıcı dil algılama eklentisi
  .use(initReactI18next) // React için i18next entegrasyonu
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      tr: {
        translation: trTranslation,
      },
    },
    fallbackLng: 'en', // Dil algılanamazsa varsayılan olarak İngilizce kullan
    interpolation: {
      escapeValue: false, // React için HTML kaçışına gerek yok
    },
  });

export const getCurrentLanguage = () => i18n.language;

export default i18n;
