// src/pages/Logout.js
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Import the AuthContext
import { logout as apiLogout } from '../api/api'; // Import logout API function

function Logout() {
  const navigate = useNavigate();
  const logoutCalled = useRef(false); // Prevent multiple requests
  const { logout } = useAuth(); // Use AuthContext's logout function

  useEffect(() => {
    const logoutUser = async () => {
      if (logoutCalled.current) return; // Prevent multiple calls
      logoutCalled.current = true; // Mark as called

      try {
        await apiLogout(); // Call API to handle backend logout
      } catch (error) {
        console.error('Error during logout:', error);
      } finally {
        // Use AuthContext's logout to clear state and token
        logout();

        // Redirect to login page
        navigate('/login');
      }
    };

    logoutUser();
  }, [navigate, logout]);

  return <div>Logging out...</div>;
}

export default Logout;
