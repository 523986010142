// src/components/Sidebar.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

import '../styles/Sidebar.css';

const Sidebar = () => {
  const { t } = useTranslation();

  const menuItems = [
    { name: t('ingredients'), link: '/ingredients', icon: 'bi-basket' },
    { name: t('products'), link: '/products', icon: 'bi-box' },
    { name: t('categories'), link: '/categories', icon: 'bi-tags' },
    { name: t('menus'), link: '/menus', icon: 'bi-menu-button' },
    { name: t('links'), link: '/links', icon: 'bi-link-45deg' },
    { name: t('company'), link: '/company', icon: 'bi-building' }, // New menu item
  ];

  return (
    <>
      {/* Offcanvas for mobile */}
      <div
        className="offcanvas offcanvas-start bg-dark text-white"
        tabIndex="-1"
        id="sidebarMenu"
        style={{ width: '250px' }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-white" id="sidebarLabel">
          <a href="/" className="d-flex align-items-center text-white text-decoration-none">
            Admin Menu
            </a>
          </h5>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-0">
          
          <ul className="nav nav-pills flex-column mb-auto">
            {menuItems.map((item, index) => (
              <li key={index} className="nav-item">
                <a href={item.link} className="nav-link text-white d-flex align-items-center">
                  <i className={`bi ${item.icon} me-2`}></i>
                  {item.name}
                </a>
              </li>
            ))}
            <li className="nav-item mt-auto">
              <hr className="text-white" />
              <div className="d-flex justify-content-center">
                <LanguageSwitcher />
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Sidebar for desktop */}
      <div
        className="d-none d-md-flex flex-column flex-shrink-0 bg-dark text-white"
        style={{ width: '250px', minHeight: '100vh', position: 'fixed', top: 0, left: 0, overflowY: 'auto' }}
      >
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none p-3"
        >
          <span className="fs-4">Admin Menu</span>
        </a>
        <ul className="nav nav-pills flex-column mb-auto">
          {menuItems.map((item, index) => (
            <li key={index} className="nav-item">
              <a href={item.link} className="nav-link text-white d-flex align-items-center">
                <i className={`bi ${item.icon} me-2`}></i>
                {item.name}
              </a>
            </li>
          ))}
          <li className="nav-item mt-auto">
            <hr className="text-white" />
            <div className="d-flex justify-content-center">
              <LanguageSwitcher />
            </div>
          </li>
        </ul>

      </div>
    </>
  );
};

export default Sidebar;
